import React, { ComponentPropsWithoutRef } from 'react'

const IconExpand = ({
  className = '',
  viewBox = '0 0 24 24',
  width = 24,
  height = 24,
  ...props
}: ComponentPropsWithoutRef<'svg'>) => (
  <svg
    className={className}
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
  >
    <path
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="2"
      d="M19 9l-7 7-7-7"
    />
  </svg>
)

export default IconExpand
