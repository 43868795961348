const IconPlus = ({
  className,
  width = '22px',
  height = '22px'
}: {
  className?: string
  width?: string
  height?: string
}) => (
  <svg width={width} height={height} viewBox="0 0 24 24" className={className}>
    <path d="M11 13V22H13V13H22V11H13V2L11 2V11H2V13H11Z" fill="currentColor" />
  </svg>
)

export default IconPlus
